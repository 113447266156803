const USER = "user";
const AUTH_TOKEN = "authToken";
const DOWNLOAD_TOKEN = "downloadToken";
const CLIENT_ID = "clientId";
const CLIENT_NAME = "clientName";
const SECOND = 1000;
const MINUTE = SECOND * 60;
const TOKEN_TTL = MINUTE * 58;
const IDLE_TIMEOUT_MIN = MINUTE * 10;
const CRORE = 10000000.0;
const PERMISSIONS = "permissions"
const ISD_CODES = [
  { label: "+91", value: "+91" },
  { label: "+971", value: "+971" },
  { label: "+81", value: "+81" },
  { label: "+852", value: "+852" },
  { label: "+61", value: "+61" },
  { label: "+65", value: "+65" },
  { label: "+44", value: "+44" },
  { label: "+41", value: "+41" },
  { label: "+1", value: "+1" },
  { label: "+974", value: "+974" },
  { label: "+975", value: "+975" },
  { label: "+965", value: "+965" },
];

export { USER, AUTH_TOKEN, PERMISSIONS,TOKEN_TTL, IDLE_TIMEOUT_MIN, CRORE, DOWNLOAD_TOKEN, ISD_CODES, CLIENT_ID, CLIENT_NAME };
