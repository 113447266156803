import moment from "moment";
import CHOICES from "CHOICES";
import { CLIENT_ID, CLIENT_NAME, PERMISSIONS } from "./constants";

const emptyProxyObject = new Proxy({}, { get: () => "" });

const isEmpty = (val) => {
  // Stolen From: https://stackoverflow.com/a/28953167
  /*
    test results
    --------------
    [] true, empty array
    {} true, empty object
    null true
    undefined true
    "" true, empty string
    '' true, empty string
    0 false, number
    true false, boolean
    false false, boolean
    Date false
    function false
    */
  if (val === emptyProxyObject) return true;
  if (val === undefined) return true;

  if (
    typeof val == "function" ||
    typeof val == "number" ||
    typeof val == "boolean" ||
    Object.prototype.toString.call(val) === "[object Date]"
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val == "object") if (Object.keys(val).length === 0) return true;

  return false;
};

const getDaysDiff = (date) => {
  let now = moment();
  return now.diff(date, "days");
};
const date = new Date();
const currentYear = date.getFullYear();
const lastTenYears = Array(10)
  .fill(0)
  .map((_, index) => currentYear - index);

const getDisplayValue = (value) => {
  // eslint-disable-next-line
  if (!value && value != 0) {
    return "-";
  } else {
    value = parseFloat(value);
    return value.toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,");
  }
};

const getDisplayIntValue = (value) => {
  // eslint-disable-next-line
  if (!value && value != 0) {
    return "-";
  } else {
    value = parseInt(value);
    return value.toLocaleString("en-IN", { maximumFractionDigits: 0 });
  }
};

const getCurrentQuarterYear = () => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();
  if (month < 3) {
    return { quarter: CHOICES.Quarter.Q4, year: year - 1 };
  } else if (month < 6) {
    return { quarter: CHOICES.Quarter.Q1, year: year };
  } else if (month < 9) {
    return { quarter: CHOICES.Quarter.Q2, year: year };
  } else if (month < 12) {
    return { quarter: CHOICES.Quarter.Q3, year: year };
  }
};

const getDateRange = (year, quarter) => {
  let startDate = "",
    endDate = "";
  if (quarter === 1) {
    startDate = `${year}-04-01`;
    endDate = `${year}-06-30`;
  } else if (quarter === 2) {
    startDate = `${year}-07-01`;
    endDate = `${year}-09-30`;
  } else if (quarter === 3) {
    startDate = `${year}-10-01`;
    endDate = `${year}-12-31`;
  } else if (quarter === 4) {
    startDate = `${year + 1}-01-01`;
    endDate = `${year + 1}-03-31`;
  }

  return [startDate, endDate];
};

const postToS3 = async (data) => {
  return fetch(data.json.url, {
    method: "POST",
    body: data.formData,
  }).then((response) => {
    return { key: data.json.fields.key, hmac: data.json.hmac };
  });
};

const buildPresignedData = (presignedPostData, file) => {
  var formData = new FormData();
  for (var k in presignedPostData.fields) {
    if (presignedPostData.fields.hasOwnProperty(k)) {
      formData.append(k, presignedPostData.fields[k]);
    }
  }
  formData.append("file", file);
  return { json: presignedPostData, formData: formData };
};

const strtodate = (dt) => {
  //return moment(dt).format("DD/MM/YYYY, hh:mm:ss A") for 12 hrs format
  return moment(dt).format("DD/MM/YYYY, HH:mm:ss"); // for 24 hrs format
};

const formatDate = (dt) => {
  if (dt === "") {
    return null;
  }
  if (dt === null) {
    return null;
  }
  return moment(dt).format("DD-MM-YYYY"); // for 24 hrs format
};

const getClientId = (id, clientName) => {
  const clientId = window.localStorage.getItem(CLIENT_ID);
  const name = window.localStorage.getItem(CLIENT_NAME);
  //window.localStorage.setItem(CLIENT_NAME,clientName)
  if (id !== undefined) {
    var n = id.slice(11);
  }
  if (clientId !== n) {
    window.localStorage.setItem(CLIENT_ID, n);
  }

  if (clientName !== undefined) {
    window.localStorage.setItem(CLIENT_NAME, clientName);
  }
};

const getPermissions = (moduleName) => {
  var modules = JSON.parse(window.localStorage.getItem(PERMISSIONS));
  var result = modules.filter((obj) => {
    return obj.module === moduleName;
  });
  return result;
};

const getFinancialYearStartdate = () => {
  var data = getCurrentQuarterYear();
  return moment(data.year, "YYYY")
    .locale("en")
    .quarter(2)
    .startOf("quarter")
    .format("YYYY-MM-DD");
};

const getFrequency = (val) => {
  if (val === 1) {
    return "Monthly";
  }
  if (val === 2) {
    return "Quaterly";
  }
  if (val === 3) {
    return "Half Yearly";
  } else {
    return "Annual";
  }
};

export {
  isEmpty,
  getFinancialYearStartdate,
  emptyProxyObject,
  lastTenYears,
  getPermissions,
  getDaysDiff,
  getDisplayValue,
  getDisplayIntValue,
  getCurrentQuarterYear,
  getDateRange,
  postToS3,
  buildPresignedData,
  strtodate,
  getClientId,
  getFrequency,
  formatDate,
};
