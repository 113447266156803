import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "index.scss";
import * as serviceWorker from "serviceWorker";
import Loader from "components/Loader";
import { createStore, StoreProvider } from "easy-peasy";
import Store from "store";

const store = createStore(Store);

const LazyApp = lazy(() => import("App"));

const App = () => {
  return (
    <StoreProvider store={store}>
      <Suspense fallback={<Loader />}>
        <LazyApp />
      </Suspense>
    </StoreProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
