import { thunk, action, computed } from "easy-peasy";
import graphql from "babel-plugin-relay/macro";

import { AUTH_TOKEN, TOKEN_TTL } from "utils/constants";
import { mutate } from "utils/relay";
import CHOICES from "CHOICES";
import { emptyProxyObject } from "utils/utils";

const mutation = graphql`
  mutation authRefreshMutation($input: RefreshInput!) {
    refreshToken(input: $input) {
      token
      payload
    }
  }
`;

const UK = CHOICES.UserKind;
const ADMINS = [UK.SUPER_ADMIN, UK.ADMIN];
const AuthStore = {
  currentUser: emptyProxyObject,
  authToken: window.localStorage.getItem(AUTH_TOKEN),

  isLoggedIn: computed((state) => state.currentUser !== UK.Client),
  isClient: computed((state) => state.currentUser.kind === UK.CLIENT),
  isDistributor: computed((state) => state.currentUser.kind === UK.DISTRIBUTOR),
  isAdmin: computed((state) => state.currentUser.kind === UK.ADMIN),
  isSuperAdmin: computed((state) => state.currentUser.kind === UK.SUPER_ADMIN),
  isAnyAdmin: computed(
    (state) => ADMINS.indexOf(state.currentUser.kind) !== -1
  ),

  setAuthToken: action((state, authToken) => {
    state.authToken = authToken;
  }),

  setCurrentUser: action((state, currentUser) => {
    state.currentUser = currentUser;
  }),

  updateAuthToken: thunk((actions, authToken) => {
    window.localStorage.setItem(AUTH_TOKEN, authToken);
    actions.setAuthToken(authToken);
  }),

  clearAuthToken: thunk((actions) => {
    window.localStorage.clear();
    actions.setAuthToken(null);
    actions.setCurrentUser(emptyProxyObject);
  }),

  setCurrentUserData: action((state, currentUser) => {
    state.currentUser = currentUser;
  }),

  fetchAuthToken: thunk(
    (actions, { mutation, input, onSuccess, onFailure }) => {
      mutate({
        mutation,
        input: input,
        onSuccess: function (data) {
          actions.updateAuthToken(data.login.token);
          onSuccess(data);
        },
        onFailure: function (messages) {
          actions.clearAuthToken();
          onFailure(messages);
        },
      });
    }
  ),

  initRefreshToken: thunk((actions) => {
    setInterval(() => {
      if (window.localStorage.getItem(AUTH_TOKEN)) {
        mutate({
          mutation,
          input: { token: window.localStorage.getItem(AUTH_TOKEN) },
          onSuccess: function (data) {
            actions.updateAuthToken(data.refreshToken.token);
          },
          onFailure: function (messages) {
            actions.clearAuthToken();
          },
        });
      }
    }, TOKEN_TTL);
  }),
};

export default AuthStore;
