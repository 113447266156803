import React from "react";
import { AUTH_TOKEN } from "utils/constants";
import {
  Environment,
  Network,
  RecordSource,
  Store,
  fetchQuery as fQ,
} from "relay-runtime";
import { commitMutation, QueryRenderer as QR } from "react-relay";
import moment from "moment";
import Loader from "components/Loader";
import Config from "config";

function network(operation, variables) {
  const authToken = window.localStorage.getItem(AUTH_TOKEN);
  let headers = { "Content-Type": "application/json" };
  if (authToken) {
    headers["Authorization"] = "JWT " + authToken;
  }
  return fetch(`${Config.BASE_URL}/graphql`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then((response) => {
    return response.json();
  });
}

const environment = new Environment({
  network: Network.create(network),
  store: new Store(new RecordSource()),
});

function mutateBase({ mutation, variables, onSuccess, onFailure }) {
  const onCompleted = (response, errors) => {
    if (errors && errors.length > 0) {
      var messages = [];
      errors.forEach((error) => {
        messages.push(error.message);
      });
      messages = messages.join("; ");
      if (onFailure) {
        return onFailure(messages);
      } else {
        return alert(messages);
      }
    }
    return onSuccess(response);
  };
  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted,
  });
}

function mutate({ mutation, input, onSuccess, onFailure }) {
  const variables = { input };
  return mutateBase({ mutation, variables, onSuccess, onFailure });
}

function QueryRenderer(_props) {
  const onSuccess = _props.onSuccess;
  return (
    <QR
      environment={environment}
      render={({ error, props }) => {
        if (error) {
          return <div> {error.message} </div>;
        }
        if (props && Object.keys(props).length > 0) {
          return onSuccess(props);
        }
        if (_props.isTable) {
          return (
            <tr>
              <td>Loading...</td>
            </tr>
          );
        }
        if (_props.isSelect) {
          return <option>Loading...</option>;
        } else {
          return <Loader />;
        }
      }}
      {..._props}
    />
  );
}

function fetchQuery(query, variables = {}) {
  return fQ(environment, query, variables);
}

export { QueryRenderer, mutate, fetchQuery, environment, mutateBase };
